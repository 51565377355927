@import '../../scss/config';

.main-app-footer {
  background-color: $main-app-color;
  // background-color: #f1f1f1;
  background-color: #fff;
  padding: 14px 0;
  text-align: center;
  font-size: 18px;
  border-top: 1px solid rgba(#000, 0.06);

  .footer-content {
    display: grid;
    gap: 12px;
    @include mediaLg {
      grid-template-columns: auto 1fr auto;
    }
    align-items: center;
    .footer-logo {
      img {
        // max-width: 100%;
        max-width: 182px;
      }
    }

    .footer-middle {
      .partners-list {
        margin-top: 18px;
        display: flex;
        flex-wrap: wrap;
        gap: 14px;
        justify-content: center;
        align-items: center;
        .p-img {
          padding: 2px;
          img {
            width: 82px;
            height: auto;
          }
        }
      }
    }
  }
  .footer-social-links {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    a {
      display: grid;
      place-items: center;
      width: 42px;
      height: 42px;
      background-color: #f1f1f1;
      border: 1px solid rgba(#000, 0.03);
      border-radius: 50%;
    }
    img {
      width: 22px;
    }
  }
}
