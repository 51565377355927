.make-order-form {
  .form-body {
    gap: 2px 22px;
    .name-phone-wrap,
    .email-nationality-wrap,
    .address-identity-wrap,
    .passwords-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 22px;
    }
  }
  .file-inputs-wrap {
    margin-top: 18px;
    padding-top: 18px;
    border-top: 1px dashed rgba(#000, 0.2);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;
  }
}
