@import '../../scss/config';
.profile-page {
  .page-title {
    h2 {
      font-size: 28px;
      text-align: center;
      @include mediaLg {
        font-size: 42px;
      }
    }
    p {
      font-size: 18px;
    }
  }

  .img-form-wrap {
    padding-bottom: 32px;
    display: grid;
    max-width: 632px;
    margin: auto;
    margin-top: 32px;
    & > img {
      align-self: center;
      // margin-top: 122px;
    }
  }

  .profile-form {
    background-color: #fff;
    padding: 32px;
    border-radius: 8px;
  }
}
