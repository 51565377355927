@import '../../scss/config';

.breadcrumb-section {
  // margin-top: 32px;
  padding-top: 32px;
  .breadcrumb-content {
    padding: 12px 14px;
    min-height: 132px;
    @include mediaLg {
      min-height: 182px;
    }
    background-size: cover;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    display: grid;
    place-items: center;
    .ant-breadcrumb {
      position: relative;
      z-index: 1;
      span {
        color: #fff;
      }
      .ant-breadcrumb-link a {
        color: #fff;
        &:hover {
          color: #000;
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $main-app-color;
      opacity: 0.86;
      background-blend-mode: multiply;
    }
  }
}
