@import '../../scss/config';

.start-selling-page {
  padding-bottom: 42px;
  .main-title {
    font-size: 42px;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
    color: $main-app-color;
    margin: 22px 0;
  }
  .form-wrapper {
    margin: auto;
    margin-top: 18px;
    background-color: #fff;
    padding: 18px 22px;
    @include mediaLg {
      padding: 32px 42px;
    }
    border-radius: 18px;
    max-width: 1032px;

    .phone-email-wrap {
      display: grid;
      gap: 14px;
      @include mediaLg {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    .names-wrap,
    .passwords-wrap {
      display: grid;
      gap: 22px;
      @include mediaLg {
        grid-template-columns: 1fr 1fr;
      }
    }
    .country-city-area-wrap {
      display: grid;
      @include mediaLg {
        grid-template-columns: 1fr 1fr 1fr;
      }
      gap: 14px;
    }
  }

  .terms-checkbox-wrap {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    .ant-form-item-control-input {
      min-height: auto;
    }
    span {
      font-size: 14px !important;
    }

    .terms-btn {
      border: 0;
      outline: 0;
      background-color: transparent;
      cursor: pointer;
      color: $main-app-color;
      text-decoration: underline;
    }
  }
}
