@import '../../scss/config';
.services-page {
  .cards-section {
    padding: 42px 0;
    .main-title {
      font-size: 42px;
      font-weight: bold;
      text-transform: capitalize;
      text-align: center;
    }
  }

  .services-ul {
    display: grid;
    gap: 22px;
    margin-bottom: 32px;
    li {
      background-color: #fff;
      border: 1px solid rgba(#000, 0.06);
      border-radius: 18px;
      overflow: hidden;
      transition: all 0.2s ease-out;
      &:hover {
        background-color: lighten($main-app-color, 46%);
        box-shadow: 0 4px 12px rgba(#000, 0.1);
      }
    }
    li,
    a {
      display: grid;
      max-width: 1032px;
    }
    .card-content {
      padding: 18px 22px;
      display: flex;
      flex-wrap: wrap;
      gap: 22px;
      align-items: center;
      .card-img {
        img {
          border-radius: 12px;
          max-height: 222px;
        }
      }
      .card-data {
        .card-name {
          color: $main-app-color;
          font-weight: bold;
          font-size: 26px;
          margin-bottom: 4px;
        }
        .card-price {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 16px;
          .price-span {
            color: $main-app-color;
            font-size: 18;
            font-weight: bold;
          }
        }
      }
    }
  }
}
