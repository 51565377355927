@import '../../scss/config';

.main-lang-btn {
  display: grid;
  place-items: center;
  span {
    display: block;
    // width: 38px;
    // height: 38px;
    // width: 32px;
    // height: 22px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: 2px 8px;
    background-color: #eee;
    // border: 1px solid rgba(#fff, 0.5);
    // border-radius: 50%;
    cursor: pointer;
    background-size: cover;
    // @include mediaLg {
    //   width: 32px !important;
    //   height: 24px !important;
    //   cursor: pointer !important;
    // }
  }
}
