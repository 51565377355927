@import '../../scss/config';

.app-layout {
  // display: flex;
  background-color: #fff;
  min-height: 100vh;
  .layout-content {
    background-color: #f9f8f8;
    min-height: 100vh;
    & > [class$='page'] {
      min-height: 100vh;
    }
  }
}
