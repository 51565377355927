@import '../../scss/config';

.cars-page {
  .cars-section {
    padding: 42px 0;
    .main-title {
      font-size: 42px;
      font-weight: bold;
      text-transform: capitalize;
      text-align: center;
      margin-bottom: 82px;
    }

    .cars-selections-ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(182px, 1fr));
      justify-content: center;
      // grid-template-columns: repeat(3, 1fr);
      gap: 32px 22px;

      .car-selection-box {
        background-color: #fff;
        padding: 10px 14px;
        border-radius: 8px;
        min-height: 122px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid rgba(#000, 0.06);
        transition: all 0.15s ease-out;

        .card-img {
          margin: auto;
          width: 100%;
          display: grid;
          justify-items: center;
          margin-bottom: 14px;
          img {
            width: 100%;
            height: 220px;
            border-radius: 14px;
            object-fit: cover;
            object-position: center;
            box-shadow: 0 0 8px rgba(#000, 0.1);
            @include mediaXlg {
              height: 252px;
            }
            @include mediaXXlg {
              height: 294px;
            }
          }
        }

        &:hover {
          background-color: lighten($main-app-color, 44%);
          border-color: lighten($main-app-color, 30%);
        }
      }
    }
  }
}
