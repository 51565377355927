@import '../../scss/config';

.forget-input-field {
  input {
    direction: ltr;
    text-align: end;
  }
}

.forget-pass-digits-form {
  display: grid;
  .digits-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-template-columns: repeat(4, 82px);
    gap: 8px;
    direction: ltr !important;
    input {
      width: 58px;
      height: 52px;
      padding: 2px 4px;
      padding-bottom: 6px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      border-radius: 4px;
      border: 1px solid rgba(#000, 0.2);
    }
  }

  .submit-btn {
    justify-self: center;
    background-color: $main-app-color;
    color: #fff;
    border-radius: 10px;
    padding: 10px 18px;
    border: 0;
    outline: 0;
    min-height: 52px;
    min-width: 132px;
    max-width: 182px;
    margin-top: 32px;
  }
}
