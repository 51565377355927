@import '../../scss/config';

.service-details-page {
  .service-details-section {
    padding: 42px 0;
    .main-title {
      font-size: 42px;
      font-weight: bold;
      text-transform: capitalize;
      text-align: center;
      margin-bottom: 82px;
    }
  }
  .gallery-details-wrap {
    @include mediaMd {
      display: flex;
      gap: 22px;
      flex-wrap: wrap;
    }
    .gallery-wrap {
      flex: 1 1;
      .image-gallery-thumbnails-container {
        margin-top: 12px;
        button {
          border: 2px solid transparent;
          width: 62px;
          height: 62px;
          border-radius: 8px;
          overflow: hidden;
          &:hover,
          &.active {
            border-color: $main-app-color;
          }
          img {
            max-height: 62px;
            object-fit: cover;
            object-position: center center;
          }
        }

        .image-gallery-thumbnail-inner {
          height: fit-content;
        }
      }
      // background-color: #fff;
      align-self: flex-start;
    }
    .details-parent-wrap {
      flex: 1 1;
      .service-name {
        color: $main-app-color;
        font-size: 26px;
        margin-bottom: 12px;
        font-weight: bold;
        display: flex;
        gap: 12px;

        .icon-wrap {
          border-radius: 5px;
          color: $main-app-color;
          font-weight: bold;
          font-size: 26px;
          text-transform: uppercase;
          display: flex;
          gap: 10px;
          align-items: center;
          img,
          svg {
            width: 28px !important;
          }
        }
      }
    }

    .ant-tabs {
      background-color: #fff;
      border-radius: 4px;
      padding: 18px 24px;
    }
  }
  .service-details-fallback-img {
    svg {
      max-height: 500px !important;
    }
  }

  .instructions-price-wrap {
    .instructions-wrap {
      .wrap-title {
        display: flex;
        gap: 10px;
        align-items: center;
        .icon-wrap {
          border-radius: 5px;
          min-width: 20px;
          display: grid;
          place-items: center;

          img,
          svg {
            max-width: 28px !important;
            max-height: 40px !important;
          }
        }
        p {
          color: $main-app-color;
          font-weight: bold;
          font-size: 26px;
          text-transform: uppercase;
        }
      }
    }

    .price-wrap {
      .wrap-title {
        display: flex;
        gap: 10px;
        align-items: center;
        .icon-wrap {
          background-color: #fff;
          border-radius: 5px;
          padding: 12px;
          min-width: 52px;
          display: grid;
          place-items: center;

          img,
          svg {
            max-width: 32px !important;
            max-height: 48px !important;
          }
        }
        p {
          color: $main-app-color;
          font-weight: bold;
          font-size: 26px;
          text-transform: uppercase;
        }
      }

      .price-itself {
        font-size: 28px;
        font-weight: bold;
        color: #9a9a9a;
        margin-right: 12px;
      }
    }
  }

  .desc-tab-content {
    display: grid;
  }

  .order-message-links-wrap {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .message-btn {
      border: 0;
      outline: 0;
      background-color: #eee;
      color: #707579;
      padding: 12px 18px;
      border-radius: 8px;
      margin-top: 12px;
      @include mediaLg {
        margin-top: 42px;
      }
      justify-self: end;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 6px;
      cursor: pointer;
      min-height: 50px;
      .icon-wrap {
        margin-bottom: -6px;
      }
    }

    .make-order-link {
      height: max-content;
      background-color: $main-app-color;
      color: #fff;
      padding: 12px 32px;
      border-radius: 8px;
      justify-self: end;
    }
  }

  .store-details-wrap {
    .ant-descriptions-header {
      margin-bottom: 6px;
    }
    .ant-descriptions-title {
      color: $main-app-color;
      font-weight: bold;
      font-size: 18px;
    }
    .ant-descriptions-view {
      border-radius: 5px;
    }
    .ant-descriptions-item-content {
      font-size: 16px;
      color: #9a9a9a;
      padding: 8px 14px;
    }
    .ant-descriptions-item-label {
      font-weight: bold;
      color: $main-app-color;
      font-size: 16px;
      padding: 8px 14px;
    }
  }

  .instructions-details,
  .desc-details {
    margin-top: 4px;
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 1.8;
    ul {
      list-style-type: disc;
      padding-right: 22px;
      margin-top: 8px;
    }
    p {
      margin-top: 12px;
      font-size: 18px;
    }
  }

  .price-itself {
    color: $main-app-color;
    font-size: 22px;
    font-weight: bold;
  }

  .whatsapp-link {
    background-color: #15950b;
    width: fit-content;
    color: #fff;
    display: flex;
    gap: 8px;
    border-radius: 200px;
    align-items: center;
    img {
      height: 46px;
    }
    span {
      padding: 8px 22px;
    }
    &.rtl {
      span {
        padding-right: 0;
      }
      img {
        padding: 8px 8px 8px 4px;
      }
    }
    &.ltr {
      span {
        padding-left: 0;
      }
      img {
        padding: 8px 4px 8px 8px;
      }
    }
  }
  .share-report-service-btns {
    margin-top: 22px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 22px;
    justify-content: space-between;

    .links-wrap {
      display: flex;
      gap: 18px;
      align-items: center;
      img,
      svg {
        width: 28px;
      }
    }
  }
  .report-service-btn {
    border: 0;
    outline: 0;
    background-color: rgb(214, 79, 79);
    color: #fff;
    height: auto;
    padding: 10px 28px;
    border-radius: 8px;
    justify-self: end;
    cursor: pointer;
  }
  .rate-modal-btn {
    border: 0;
    outline: 0;
    background-color: lighten($main-app-color, 12%);
    height: auto;
    padding: 8px 28px;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    margin: 8px 0;
  }

  .price-q-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 22px;
    align-items: center;

    .quantity-wrap {
      color: $main-app-color;
      background-color: #eee;
      padding: 10px 22px;
      border-radius: 4px;
    }
  }
}
