@import '../../scss/config';

.main-app-bar-lg {
  position: relative;
  z-index: $app-header-z-index;
  background-color: $main-app-color;
  &.not-home {
    background-color: #fff;
  }
  .nav-content-wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    gap: 18px;
    align-items: center;
    &.not-home {
      grid-template-columns: auto auto;
      .appbar-signin-link,
      .dashboard-link {
        background-color: $main-app-color;
        color: #fff;
      }

      .nav-lang-wrapper {
        & > ul {
          li {
            a {
              color: $main-text-color;
            }
          }
        }
      }
    }
    .main-app-bar-logo {
      padding: 2px 0;
      padding-top: 14px;
      padding-top: 4px;
      img {
        height: 64px;
        // @include mediaXXlg {
        //   height: 86px;
        // }
        transition: all 0.25s ease-out;
      }
    }

    .appbar-signin-link {
      background-color: #fff;
      color: $main-app-color;
      padding: 12px 18px;
      border-radius: 12px;
      width: fit-content;
    }
    .dashboard-link {
      padding: 12px 18px;
      background-color: #fff;
      color: $main-app-color;
      border-radius: 12px;
      width: fit-content;
    }

    .nav-lang-wrapper {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 18px;
      & > ul {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 32px;
        font-weight: bold;
        li {
          display: grid;
          // align-items: center;
          a {
            display: grid;
            justify-items: center;
            align-items: center;
            grid-template-rows: 1fr auto;
            // padding-bottom: 14px;
            position: relative;
            color: #fff;

            .active-img-wrap {
              width: 44px;
              height: 2px;
              position: absolute;
              bottom: 0;
            }
            &.active-link {
              color: $main-app-color;
              color: $secondary-app-color;
              .active-img-wrap {
                // background-image: url(../../assets/imgs/bgs/active-link-bg.png);
                background-color: $main-app-color;
                background-color: $secondary-app-color;
              }
            }
          }
        }
      }
    }
  }

  &.light {
    .nav-content-wrapper {
      .nav-lang-wrapper {
        & > ul {
          li {
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }

  @include mainAppBarHeight;
  transform: translateY(0);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.25s ease-out;
  display: grid;
  align-items: center;

  &.exceeds0 {
    border-radius: 0 0 12px 12px;
    box-shadow: 0 12px 14px rgba(#000, 0.1);
    background-color: #fff;
    z-index: calc(#{$app-header-z-index} + 1);
    // &.scroll-up {
    // }

    .nav-content-wrapper {
      grid-template-columns: auto auto;
      .appbar-signin-link {
        background-color: $main-app-color;
        color: #fff;
      }
      .dashboard-link {
        background-color: $main-app-color;
        color: #fff;
      }

      .main-app-bar-logo {
        padding-top: 10px;
        padding-top: 0px;
        img {
          height: 52px;
          // height: 78px;
        }
      }
      .nav-lang-wrapper {
        & > ul {
          li {
            a {
              color: $main-text-color;
            }
          }
        }
      }
    }
    &.scroll-down {
      transform: translateY(-100%);
    }
  }

  display: none;
  @include mediaLg {
    display: grid;
  }

  .profile-menu-btn {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    padding: 0;
    width: 44px;
    height: 44px;
    border: 1px solid rgba(#000, 0.1);
    border-radius: 50%;
  }

  .main-app-notifications {
    cursor: pointer;
    display: grid;
    width: 44px;
    height: 44px;
    background-color: #f9f8f8;
    border-radius: 50% !important;
    display: grid;
    place-items: center;
    .notification-btn-wrap {
      display: grid;
      position: relative;
      width: fit-content;
      svg {
        width: 24px;
        height: 24px;
        // path {
        //   fill: $main-app-color;
        // }
      }
      .active-notifications {
        background-color: #ff8f8f;
        border: 2px solid #fff;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        top: -6px;
        right: -1px;
      }
    }
  }
}

.notifications-dropdown-ul {
  // background-color: $main-app-color !important;
  // min-width: 232px;
  // overflow: auto;
  max-width: 322px;
  @include mediaLg {
    max-width: 732px;
  }
  max-height: 60vh;
  // overflow: hidden;
  // overflow-y: auto;
  @include custom-scrollbar;
  border-radius: 8px !important;
  padding: 12px 0;
  .notifications-menu-link {
    padding: 0;
    .ant-dropdown-menu-title-content {
      border: 1px solid rgba(#000, 0.05);
      display: grid;
      background-color: transparent !important;
    }
    .notification-content {
      display: grid;
      grid-auto-flow: column;
      // text-align: center;
      padding: 10px 18px;
      transition: all 0.2s ease-out;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 12px;
      align-items: center;
      .notification-data {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 12px;
      }
      .notification-title {
        color: $main-app-color;
        font-weight: bold;
        &.rtl {
          border-left: 1px solid rgba(#000, 0.1);
          padding-left: 8px;
        }
        &.ltr {
          border-right: 1px solid rgba(#000, 0.1);
          padding-right: 8px;
        }
      }
      .notification-title,
      .notification-desc {
        white-space: pre-wrap;
      }
      &:hover {
        background-color: #f1f1f1;
        box-shadow: 0 1px 2px rgba(#fff, 0.1);
      }
    }
  }
}
.ant-dropdown-menu {
  min-width: 232px;
}
