.sub-categories-page {
  .sub-categories-section {
    padding: 42px 0;
    .main-title {
      font-size: 42px;
      font-weight: bold;
      text-transform: capitalize;
      text-align: center;
      margin-bottom: 82px;
    }
  }
}
