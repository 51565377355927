@import '../../scss/config';

.custom-shared-btn {
  font-size: 18px !important;
  // transform: skew(-14deg) !important;
  text-transform: uppercase !important;
  padding: 10px 32px !important;
  cursor: pointer;
  border: 0 !important;
  border-radius: 6px !important;
  color: #fff !important;
  height: auto !important;
  outline: none;
  background-color: $main-app-color;
  display: flex;
  justify-content: center;
  gap: 4px;
  // & > span,
  // .btn-content {
  //   transform: skew(14deg);
  // }
  transition: all 0.2s ease-out;
  &:hover {
    color: #fff;
    background-color: darken($main-app-color, 10%);
    box-shadow: 0 4px 6px rgba(#000, 0.2);
  }
}
