@import '../../scss/config';

.post-form {
  background-color: #fff;
  padding: 14px;
  @include mediaLg {
    padding: 32px;
  }
  display: grid;
  .form-body {
    display: grid;
  }
  .custom-text-input {
    label {
      font-size: 22px;
      color: rgba(#fff, 0.6);
    }
    .ant-input-affix-wrapper {
      height: 56px;
      background-color: #fff;
      color: #262626;
      border-radius: 4px;
    }
    input {
      color: #262626;
      &::placeholder {
        color: #262626;
      }
    }
  }

  .forgot-link {
    color: #1bee95;
    justify-self: end;
  }

  .submit-btn {
    text-transform: capitalize;
    margin-top: 4px;
    justify-self: end;
  }
}
