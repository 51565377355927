@import '../../scss/config';

.make-order-page {
  .make-order-section {
    margin-top: 32px;
    padding-bottom: 42px;
    display: grid;
    @include mediaLg {
      grid-template-columns: 1.2fr 1fr;
    }
    gap: 12px;

    .service-name {
      color: $main-app-color;
      font-size: 26px;
      margin-bottom: 8px;
      font-weight: bold;
      display: flex;
      gap: 12px;

      .icon-wrap {
        // background-color: #f2f2f2;
        padding: 8px;
        border-radius: 5px;
        color: $main-app-color;
        font-weight: bold;
        font-size: 26px;
        text-transform: uppercase;
        display: flex;
        gap: 10px;
        align-items: center;
        img,
        svg {
          width: 32px !important;
        }
      }
    }
    .brief-wrap {
      background-color: #fff;
      padding: 28px 32px;
      border-radius: 12px;
      overflow: hidden;
      min-height: 332px;
      align-self: start;
      .bg-wrap {
        position: absolute;
        background-size: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        margin: 22px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 4px;
        overflow: hidden;
      }
      .brief-content {
        z-index: 3;
        position: relative;
        .store-name {
          color: $main-app-color;
          font-size: 22px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .price-wrap {
          margin-top: 22px;
          .price-title {
            color: $main-app-color;
            font-size: 18px;
            font-weight: bold;
          }
          .price-itself {
            font-size: 20px;
            font-weight: bold;
            color: $main-app-color;
            margin-right: 12px;
          }
        }

        .service-img {
          margin-top: 12px;
          background-color: transparent;
          svg {
            width: 82px;
          }
          img {
            max-width: 100%;
          }
        }
      }
    }
    .make-order-form-wrap {
      background-color: #fff;
      padding: 18px 22px;
      border-radius: 12px;
    }
  }

  .instructions-details {
    margin-top: 4px;
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 1.8;
    ul {
      list-style-type: disc;
      padding-right: 22px;
      margin-top: 8px;
    }
    p {
      margin-top: 12px;
      font-size: 18px;
    }
  }

  .store-details-wrap {
    .ant-descriptions-bordered .ant-descriptions-view {
      border-color: rgba(#000, 0.1);
    }
    .ant-descriptions-bordered .ant-descriptions-row {
      border-color: rgba(#000, 0.1);
    }
    th {
      border-color: rgba(#000, 0.1) !important;
      background-color: rgba(#fff, 0.8) !important;
    }
    td {
      background-color: rgba(#fff, 0.2);
    }
    .ant-descriptions-header {
      margin-bottom: 6px;
    }
    .ant-descriptions-title {
      color: $main-app-color;
      font-weight: bold;
      font-size: 26px;
    }
    .ant-descriptions-view {
      border-radius: 5px;
    }
    .ant-descriptions-item-content {
      color: #626262;
      padding: 8px 14px;
    }
    .ant-descriptions-item-label {
      font-weight: bold;
      color: $main-app-color;
      font-size: 15px;
      padding: 8px 14px;
    }
  }
}
