@import '../../scss/config';

.my-orders-page {
  .curr-prev-orders-btns-wrap {
    display: flex;
    justify-content: center;
    gap: 1px;
    border-radius: 14px;
    width: max-content;
    margin: auto;
    margin-top: 22px;
    margin-bottom: 32px;
    background-color: rgba(#000, 0.1);
    border-radius: 14px;
    overflow: hidden;
    border: 1px solid rgba(#000, 0.04);

    .curr-orders-btn,
    .prev-orders-btn {
      padding: 14px 28px;
      background-color: #fff;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.2s ease-out;
      &.selected {
        background-color: $main-app-color !important;
        color: #fff;
      }
      &:hover {
        background-color: lighten($main-app-color, 38%);
      }
    }
  }

  .my-orders-wrap {
    max-width: 1092px;
    margin: auto;
    .my-orders-list {
      display: grid;
      background-color: #fff;
    }
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    position: absolute;
    top: 50%;
    left: 22px;
    right: auto;
    margin: 0;
    transform: translateY(-50%);
  }

  .ant-collapse.ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 22px !important;
    left: auto !important;
  }

  .ant-collapse-item {
    position: relative;
    overflow: hidden;
    padding: 0;
    display: grid;
    align-items: center;
    border: 0 !important;
    background-color: #fff;
    border-radius: 8px !important;
    border: 1px solid rgba(#000, 0.04) !important;
    margin-bottom: 14px;
    box-shadow: 0 0 4px rgba(#000, 0.04);
  }

  .list-header {
    min-height: 112px;
    padding: 12px 18px;
    display: grid;
    gap: 12px;
    @include mediaLg {
      grid-template-columns: auto 1fr auto auto;
    }
    align-items: center;
    .img-wrap {
      img {
        width: 162px;
        border-radius: 8px;
      }
    }

    .name-address-wrap {
      @include mediaLg {
        margin: 0 32px;
      }
      color: #9a9a9a;
      .service-name {
        font-weight: bold;
        font-size: 20px;
        // text-align: center;
      }
      .address-wrap {
        display: flex;
        // align-items: center;
        gap: 6px;
        margin-top: 4px;
        font-size: 16px;
        svg {
          margin-top: 4px;
        }
      }
    }
    .price-method-wrap {
      @include mediaLg {
        &.rtl {
          margin-left: 92px;
          margin-right: 32px;
        }
        &.ltr {
          margin-right: 92px;
          margin-left: 32px;
        }
      }
      .service-price {
        justify-self: end;
        font-size: 16px;
        color: #828282;
        font-weight: bold;
      }
      .payment-method-wrap {
        margin-top: 4px;
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }
    .cancel-btn {
      border: 0;
      display: grid;
      place-items: center;
      background-color: #f7f8fa;
      background-color: #ffd1e5;
      cursor: pointer;
      svg {
        fill: #ff328b;
      }
      width: 48px;
      height: 48px;
      border-radius: 50%;
      &.rtl {
        margin-left: 92px;
      }
      &.ltr {
        margin-right: 92px;
      }
    }
  }
  .collapse-icon-wrap {
    display: inline-block;
    background-color: #f7f8fa;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    display: grid !important;
    place-items: center;
    transition: 0.3s ease-out;
    top: 33% !important;
    transform: translateY(0) !important;
    svg {
      width: 22px;
      height: 22px;
      fill: #828282;
    }
    &.opened {
      transform: translateY(0) rotate(180deg) !important;
    }
  }

  .order-badge {
    padding: 4px 12px;
    position: absolute;
    top: 0;
    &.ltr {
      left: auto;
      right: 0;
      border-bottom-left-radius: 10px;
    }
    &.rtl {
      left: 0;
      right: auto;
      border-bottom-right-radius: 10px;
    }
    &.new-order-badge {
      background-color: rgb(255, 236, 200);
      color: rgb(194, 128, 7);
    }
    &.in-progress-order-badge {
      background-color: rgb(255, 236, 200);
      color: rgb(194, 128, 7);
    }
    &.done-order-badge {
      background-color: rgb(156, 235, 208);
      color: rgb(12, 136, 95);
    }
    &.canceled-order-badge {
      background-color: #f8c4cd;
      color: #b83a4f;
    }
  }
}
