@import '../../scss/config';

.main-app-custom-shared-card {
  background-color: lighten($main-app-color, 44%);
  border: 1px dashed lighten($main-app-color, 30%);
  display: grid;
  height: calc(100% - 32px);
  border-radius: 8px;
  .card-content {
    transform: translateY(-32px);
  }
  .card-img {
    margin: auto;
    width: 95%;
    display: grid;
    justify-items: center;
    img {
      width: 100%;
      height: 220px;
      border-radius: 14px;
      object-fit: cover;
      object-position: center;
      box-shadow: 0 0 8px rgba(#000, 0.1);
      @include mediaXlg {
        height: 252px;
      }
      @include mediaXXlg {
        height: 294px;
      }
    }
  }
  .card-data {
    padding: 10px 14px;
    .card-name {
      font-size: 18px;
      margin-bottom: 8px;
      text-align: center;
      margin-top: 8px;
      font-weight: bold;
      color: $main-app-color;
    }
  }
  transition: all 0.2s ease-out;
  &:hover {
    background-color: lighten($main-app-color, 40%);
  }
}
